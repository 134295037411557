import React, { Component } from 'react'
import { Title, Gap, Col, Container, Link, Button } from '../components'

class Page404 extends Component {
  render() {
    return (
      <Container>
        <Gap.Top />
        <Col width="100%" justifyContent="center" alignItems="center">
          <Title textAlign="center">404 - Stránka nebyla nalezena</Title>
          <Gap gap="50px" />
          <Link to={Link.INDEX}>
            <Button>Jít na hlavní stránku</Button>
          </Link>
        </Col>
        <Gap gap="200px" />
        <Gap gap="400px" />
      </Container>
    )
  }
}

export default Page404
